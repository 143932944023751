import { $post } from '@/api/http.js'
import tool from '@/util/tool.js'
import common from "@/api/common.js"

class Forum{
    /**
     * 获取留言列表
     * @param {number} page 
     * @param {number} limit 
     * @param {function} fn 
     */
    getMessgae(page,limit,fn){
        let param = { page,limit}
        common.getDataList("ComArticleList",param).then(res=>{
            fn(res)
        })
    }
    /**
     * 删除留言
     * @param {number} id 
     * @param {function} fn 
     */
    deleteMessage(id,fn){
        tool.confirm("确认删除该消息吗？").then(()=>{
            $post("delComArticle",{id}).then(res=>{
                tool.message("删除成功")
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 获取敏感词汇
     * @param {function} fn 
     */
    getSensitiveWord(fn){
        $post("getsitiveWordsList").then(res=>{
            fn(res.data.c)
        })
    }

    /**
     * 设置敏感词汇
     * @param {string} word 
     */
    setSensitive(word){
        $post("setsitiveWordsList",{ c:word }).then(()=>{
            tool.message("保存成功")
        })
    }


}
const ForumModel = new Forum()
export default ForumModel