<template>
	<!-- 留言列表 -->
	<div class="adhibition-forum el-content">
		<a-tabs defaultActiveKey>
			<a-tab-pane key="first" tab="留言列表">
				<a-table :pagination="false" :data-source="info.list" row-key="id" :columns="[
					{title:'ID',dataIndex:'id'},
					{title:'发布人',dataIndex:'user.nickname'},
					{title:'内容',dataIndex:'content',slots:{customRender:'content'},width:400},
					{title:'视频',dataIndex:'video',slots:{customRender:'video'}},
					{title:'发布时间',dataIndex:'create_time'},
					{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
				]" >
					<template #content="{record}">
						<div>{{ record.content }}</div>
						<div v-if="record.images">
							<a-image
							    style="width: 40px;height: 40px;margin: 2px 2px 0 0;cursor: pointer;"
							    v-for="(item,index) in record.images"
							    :src="item" :key="index">
							</a-image>
						</div>
					</template>
					<template #video="{record}">
						<a-button v-if="record.video" type="link" @click="playVideo(record.video)">播放视频</a-button>
						<span v-else>暂无</span>
					</template>
					<template #action="{record}">
						<a-button 
							type="link" 
							v-has="{action:'wxapp_adhibition_message_del',plat:0}"
							@click="delMessage(record.id)" >删除
						</a-button>
					</template>
				</a-table>
				<div class="pager">
					<a-pagination
						show-size-changer
						:default-current="info.page"
						:total="info.count"
						@showSizeChange="(p,e)=>{getMessageList(info.page,e)}"
						@change="(e)=>{getMessageList(e,info.limit)}"
					/>
				</div>
			</a-tab-pane>
			<a-tab-pane key="second" tab="设置">
				<com-power-form :form-data="setFormData"></com-power-form>
			</a-tab-pane>
			<a-tab-pane key="third" tab="敏感词设置">
				<a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 8 }">
					<a-form-item label="敏感词设置">
						<a-textarea v-model:value="word" placeholder="敏感词设置" :rows="16" />
					</a-form-item>
					<a-form-item :wrapper-col="{ offset: 2 }">
						<a-button type="primary" @click="setSensitive">保存</a-button>
					</a-form-item>
				</a-form>	
			</a-tab-pane>
		</a-tabs>
		<a-modal v-model:visible="video.show" title="视频播放" :footer="null" @cancel="video.show = false">
			<video style="max-width:400px" :src="video.url" controls="controls"></video>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import forumModel from '@/api/addons/forum.js'
import userModel from '@/api/user.js'
import comPowerForm from '@/components/form/com-power-form.vue'
export default{
    components:{
        comPowerForm
    },
	setup(){
		const _d = reactive({
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			},
			word:"", //敏感词
			video:{
				show:false,
				url:''
			},
			setFormData:[
				{label:'全员限制是否开放发言',type:'switch',key:'account_development_speech',dataType:'string'},
				{label:'注册时间大于',type:'number',key:'registration_time_can_speak',dataType:'string',addon:'天可发言'},
				{label:'会员等级大于',type:'select',key:'account_grade_can_speak',dataType:'string',
					options:[{label:'请选择会员等级',value:''}]
				},
				{label:'视频上传大小限制',type:'number',key:'file_max_size',dataType:'string',addon:'M'},
				{label:'发言提示',type:'text',key:'message_publish_tip',dataType:'string'},
			]
		})
		getMessageList(1,_d.info.limit)
		getSensitiveWord()
		userModel.getAccountLevel(1,999,res=>{
			_d.setFormData.forEach(item=>{
				if( item.key == 'account_grade_can_speak'){
					res.list.forEach(val=>{
						item.options.push({
							label:val.level_name,
							value:''+val.id
						})
					})
				}
			})
		})
		function getMessageList(page,limit){
			forumModel.getMessgae(page,limit,res=>_d.info = {limit,...res})
		}

		function getSensitiveWord(){
			forumModel.getSensitiveWord(res=>_d.word = res)
		}
		function playVideo(video){
			_d.video.url= video
			_d.video.show= true
		}
		const delMessage = (id)=>forumModel.deleteMessage(id,()=>getMessageList(_d.info.page,_d.info.limit))
		
		const setSensitive = ()=>forumModel.setSensitive(_d.word)

		return{
			...toRefs(_d),
			getMessageList,
			delMessage,
			setSensitive,
			getSensitiveWord,
			playVideo
		}
	},
}
</script>
<style lang="scss">
</style>
